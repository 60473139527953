/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* header *****************************************/
    /**********************************************************************************************/

	.solutions {
        padding: 1.7vw;
        letter-spacing: .15vw;
        font-size: 2.2vw;
        top: 55vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* header *****************************************/
    /**********************************************************************************************/

	.navbar-closed {
		height: 6vw;
	}

	.navbar__logoMutuactW {
		left: 4vw;
		width: 6vw;
	}

	.navbar__button-x {
		top: 1.8vw;
	}
	
	.navbar__button-bars {
		top: 2vw;
	}

	.nav__menu__link-header {
        margin-top: 1.5vw;
		font-size: 1.7vw;
        letter-spacing: .073vw;
    }

	.nav__menu__text {
		font-size: 1.5vw;
	}

    .actu {
        margin-right: 8.2vw;
    }

	.b-contact {
		margin-right: .6vw;
	}

	.devis {
		float: left;
		margin-left: 3vw;
	}

	.devis-hidden {
		display: none;
	}

	.devis-visible {
		display: block;
	}

	.solutions {
        font-size: 1.8vw;
        top: 53vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* header *****************************************/
    /**********************************************************************************************/
	.navbar-closed {
		height: 4.5vw;
	}
	
	.navbar-open {
		height: 47.085vw;
	}

	.navbar__logoMutuactW {
		top: 1.5vw;
		width: 6vw;
		left: 2vw;
	}

	.navbar__button-x {
		top: 1.296vw;
		right: 2.92vw;
		font-size: 1.825vw;
	}
	
	.navbar__button-bars {
		top: 1.542vw;
		right: 2.92vw;
		font-size: 1.46vw;
	}
	
	menu {
		margin-top: 7vw;
		width: 70vw;
	}
	
	.navbar__menu-open {
		top: 2.5vw;
		height: auto;
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.nav__menu__link {
		line-height: 2.19vw;
		letter-spacing: .146vw;
		font-size: 35px;
	}
    
    .nav__menu__link-header {
		width: auto;
		border-radius: 4px;
		padding: .6vw 1vw .6vw 1vw;
        margin-top: .9vw;
		font-size: 1.24vw;
        letter-spacing: .073vw;
		display: inline-block;
		color: white;
    }

	.nav__menu__text {
		font-size: 1.3vw;
	}

    .actu {
		border: solid 1px white;
        margin-right: 7.3vw;
		background-color: transparent;
		transition: background-color .4s;
    }

	.b-contact {
		margin-right: .6vw;
	}

	.devis {
		float: left;
		margin-left: 3vw;
	}

	.devis-hidden {
		display: none;
	}

	.devis-visible {
		display: block;
	}

	.nav__menu__bienvenue {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		margin-top: -8.7vw;
		font-size: 1.5vw;
		font-weight: 600;
		color: white; 
		letter-spacing: .08vw;
	}

	.solutions {
        font-size: 1.4vw;
        padding: 1.5vw;
        top: 38vw;
    }
}