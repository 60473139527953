/**********************************************************************************************/
/********************************************* header *****************************************/
/**********************************************************************************************/

.navbar {
	position: relative;
    width: auto;
	background: transparent;
	transition: background-color 2s;
	z-index: 1;
}

.navbar-open {
	height: 126.4vw;
	transition: height .4s, background-color 1.5s;
	background: linear-gradient(to right, rgba(150, 134, 42, 0.2),rgba(78, 67, 7, 0.2));
}

.navbar-closed {
	height: 30vw;
	background: linear-gradient(to right, rgb(52, 190, 167), rgb(10, 86, 109));
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	transition: height .3s, background-color .8s;
}

.navbar__logoMutuactW {
	display: none;
	position: relative;
	top: 5vw;
	left: 7vw;
	width: 9vw;
	height: auto;
	filter: brightness(130%);
}

.logo-hidden {
	opacity: 0;
}

.logo-visible {
	opacity: 9;
}

.navbar__button-x {
	position: absolute;
    float: right;
	top: 10.5vw;
	right: 8.2vw;
	font-size: 4.4vw;
	color: white;
	transition: color .4s;
	cursor: pointer;
	z-index: 500;
}

.navbar__button-x:hover {
	color: rgb(204, 1, 1);
	transition: color .4s;
}

.navbar__button-bars {
	position: absolute;
    float: right;
	top: 10.5vw;
	right: 8.2vw;
	font-size: 4.4vw;
	color: white;
	cursor: pointer;
	z-index: 500;
}

.closed {
	display: none;
}

.open {
	display: block;
}

menu {
	position: absolute;
	text-align: center;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	margin-top: 8vw;
	padding: 0;
	right: 0;
	left: 50%;
	transform: translate(-50%, 0);
	overflow: hidden;
	width: 70vw;
}

.navbar__menu {
	display: flex;
	flex-wrap: wrap;
	width: 70vw;
}

.navbar__menu-open {
	top: 23vw;
	height: 75vw;
	padding-top: 5.3vw;
	padding-bottom: 0;
	transition-delay: .15s;
	transition-duration: .13s;
}

.navbar__menu-closed {
	height: 0;
}

.nav__menu__link {
	position: relative;
	line-height: 2vw;
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
	letter-spacing: .076vw;
	font-size: 7vw;
	color: white;
	cursor: pointer;
	transition: all .3s;
}

.nav__menu__link {
	width: 30vw;
}

.nav__menu__link:hover {
	text-decoration: none;
	color: rgb(142, 252, 233);
	transition: all .2s;
}

.nav__menu__link-header {
	float: right;
	margin-top: 21vw;
	font-family: 'Open', sans-serif;
	font-size: 4vw;
	font-weight: 400;
	letter-spacing: .075vw;
	cursor: pointer;
	width: auto;
	border-radius: 4px;
	padding: .6vw 1vw .6vw 1vw;
	color: white;
}

.nav__menu__text {
	margin-top: 2vw;
	font-size: 4vw;
	cursor: pointer;
}

.nav__menu__text:hover {
	text-decoration: underline;
	text-underline-position: under;
}

.devis {
	float: left;
	border: solid 1px rgb(36, 122, 108); 
	background-color: rgb(36, 122, 108);
	transition: .4s;
	margin-left: 8vw;
}

.devis:hover {
	border: solid 1px rgb(44, 151, 133);
	background-color: rgb(44, 151, 133); 
	transition: .4s;
}

.b-contact {
	margin-right: 1vw;
	border: solid 1px rgb(228, 0, 87);
	background-color: rgb(228, 0, 87);
	transition: .4s;
}

.b-contact:hover {
	border: solid 1px rgb(255, 0, 98);
	background-color: rgb(255, 0, 98);
	transition: .4s;
}

.actu {
	border: solid 1px white;
	background-color: transparent;
	transition: background-color .4s;
	margin-right: 15.7vw;
}

.actu:hover {
	background-color: rgba(255, 255, 255, .3);
	transition: background-color .4s;
}

.actu-visible {
	display: block;
}

.actu-hidden {
	display: none;
}

.actu-container {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 30vw;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 700px;
    width: 90vw;
    height: auto;
    border-radius: 5vw;
    background-color: white;
	font-family: Open, sans-serif;
    color: black;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    z-index: 8500;
}

.actu-container__closed {
    position: absolute;
    right: 5vw;
    margin-top: 5vw;
    font-size: 8vw;
    cursor: pointer;
	color: rgb(148, 148, 148);
    transition: color .4s;
}

.actu-container__closed:hover {
    color: rgb(204, 1, 1);
    transition: color .4s;
}

.actu-container>header>h1 {
    margin-top: 12vw;
    text-align: center;
    letter-spacing: .2vw;
    font-size: 6vw;
	font-weight: normal;
	color: black;
}

.actu-container>h2 {
	font-weight: normal;
    color: black;
}

.actu-container>p {
    position: relative;
    margin-bottom: 8vw;
    text-align: left;
    font-size: 4.5vw;
    line-height: 7vw;
    letter-spacing: .2vw;
}

.ici, 
.vowd {
	text-decoration: none;
	color: rgb(228, 0, 87);
}

.ici:hover,
.vowd:hover {
	text-decoration: underline;
	text-underline-position: under;
}

.solutions {
	position: absolute;
	margin: 0;
    width: auto;
    padding: 3vw;
    background: linear-gradient(rgb(52, 190, 167), rgb(10, 86, 109));
    border-radius: 5vw;
	border: solid 2px white;
    cursor: pointer;
    font-family: 'Open', sans-serif;
    letter-spacing: .3vw;
    font-size: 4vw;
    font-weight: bold;
	top: 122vw;
	left: 50%;
	transform: translate(-50%, 0);
    filter: brightness(100%);
    transition: filter .4s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index: 8000;
}

.solutions:hover {
    filter: brightness(120%);
    transition: filter .4s;
}

.solutions:active {
	filter: brightness(200%);
}

.solutions-visible {
	display: inline-block;
}

.solutions-hidden {
	display: none;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* actu ******************************************/
    /**********************************************************************************************/
	
	.navbar-closed {
		height: 8vw;
	}
	
	.navbar-open {
		height: 56.43vw;
	}

	.navbar__logoMutuactW {
		top: 1.6vw;
		left: 5vw;
		width: 6vw;
	}

	.navbar__button-x {
		top: 3vw;
		right: 4.2vw;
		font-size: 2.624vw;
	}
	
	.navbar__button-bars {
		top: 3.3vw;
		right: 4.2vw;
		font-size: 2.1vw;
	}
	
	menu {
		margin-top: 7vw;
		width: 80vw;
	}

	.navbar__menu {
		display: flex;
		flex-wrap: wrap;
		width: 90vw;
	}

	.navbar__menu-open {
		top: 3.5vw;
		height: 20vw;
		padding-top: 0;
		padding-bottom: 0;
	}
	
	.nav__menu__link {
		width: auto;
		padding-top: 0vw;
		padding-left: 0vw;
		padding-bottom: 0vw;
		line-height: 0;
		letter-spacing: .175vw;
		font-size: 3vw;
	}

	.nav__menu__link-header {
        margin-top: 2.2vw;
		font-size: 2vw;
        letter-spacing: .087vw;
    }
	
	.nav__menu__text {
		margin-top: 1.4vw;
		font-size: 1.8vw;
	}

    .actu {
        margin-right: 8vw;
    }

    .actu:hover {
        text-decoration-thickness: .175vw;
    }

	.b-contact {
		display: block;
		margin-right: .6vw;
	}

	.devis {
		float: left;
		margin-left: 4vw;
	}

	.devis-hidden {
		display: none;
	}

	.devis-visible {
		display: block;
	}

	.actu-container {
        top: 150px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 30px;
    }
    
    .actu-container__closed {
        right: 25px;
        margin-top: 20px;
        font-size: 30px;
    }

    .actu-container>header>h1 {
        margin-top: 40px;
        letter-spacing: 1px;
        font-size: 22px;
    }
    
    .actu-container>p {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 1px;
    }

	.solutions {
        padding: 1.7vw;
        letter-spacing: .15vw;
        font-size: 2.2vw;
        top: 45vw;
    }
}