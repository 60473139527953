/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/
    
    .containerOffice__bloc {
        margin-top: -8vw;
        margin-bottom: -8vw;
    }

    .containerOffice__bloc__office__text__content {
        height: 31vw;
        margin-top: 10vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/
    
    .containerOffice__bloc {
        margin-top: -7vw;
        margin-bottom: 0;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* agences ****************************************/
    /**********************************************************************************************/

    .containerOffice__bloc {
        margin-top: -4;
        margin-bottom: 0;
    }

    .containerOffice__bloc__office__text__content {
        height: 31vw;
        margin-top: 12vw;
    }
}