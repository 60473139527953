input[type="checkbox"] {
    height: 1.5rem;
    width: 1.5rem;
    margin: 5px;
    display: inline-block;
    appearance: none;
    position: relative;
    background-color: rgb(235, 235, 235);
    border-radius: 15%;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgb(180, 180, 180);
}

input[type="checkbox"]::after {
    content: '';
    display: block;
    height: 0.7rem;
    width: .3rem;
    border-bottom: .31rem solid #a0ffe7;
    border-right: .31rem solid #a0ffe7;
    opacity: 0;
    transform: rotate(45deg) translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 20%;
    transition: .15s ease;
}

input[type="checkbox"]::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    background-color: rgb(10, 86, 109);
    border-radius: 30%;
    opacity: .5;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: .3s ease;
}

input[type="checkbox"]:checked::before {
    height: 130%;
    width: 130%;
    opacity: 100%;
}

input[type="checkbox"]:checked::after {
    opacity: 100%;
}