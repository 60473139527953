@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /***************************************** contact-form ***************************************/
    /**********************************************************************************************/

    .form {
        width: 40vw;
    }

    .form-group > input,
    .form-group > textarea {
        margin-bottom: auto;
        font-size: 1.8vw;
        border: solid 2px rgb(10, 86, 109);
    }

    .form-group input:hover,
    .form-group textarea:hover {
        border: solid 2px rgb(52, 190, 167);
    }

    .form-group > textarea {
        height: 12vw;
    }
    
    .form-group input {
        height: 5.38vw;
    }

    .error-message {
        font-size: 1.4vw;
        line-height: 2vw;
    }

    .count-message {
        font-size: 1.3vw;
        line-height: 2.3vw;
    }

    .contact-legal-content {
        margin-top: .7vw;
    }

    .contact-legal-content__text {
        font-size: 1.4vw;
        line-height: 2vw;
    }

    .label-recap {
        font-size: 1.1vw;
        line-height: 1.6vw;
    }
}

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /***************************************** contact-form ***************************************/
    /**********************************************************************************************/

    .form-group > input,
    .form-group > textarea {
        font-size: 1.6vw;
    }

    .form-group > textarea {
        font-size: 1.6vw;
        margin-bottom: 1vw;
    }

    .count-message {
        margin-left: .3vw;
        margin-bottom: 1.5vw;
        font-size: 1.2vw;
        line-height: 0;
    }

   
    .error-message {
        font-size: 1.2vw;
        line-height: 1.8vw;
    }

    .contact-legal-content {
        margin-top: 1vw;
    }

    .contact-legal-content__text {
        font-size: 1.2vw;
        line-height: 2.2vw;
    }

    .contact-submit {
        padding: 1.8vw;
        margin-bottom: 0.876vw;
        border-radius: 0.438vw;
        font-size: 1.825vw;
        letter-spacing: .0365vw;
    }
}