/**********************************************************************************************/
/********************************************* vowd *******************************************/
/**********************************************************************************************/

.vowd {
    position: relative;
    width: 100%;
    height: auto;
}

.vowd__img {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
}

.vowd__filtre {
    width: 100%;
    height: 65.6vw;
    padding: 5vw;
    align-items: center;
    font-size: 7vw;
    font-weight: 500;
    line-height: 8vw;
    font-family: 'Open', sans-serif;
    background-color: rgba(01, 01, 01, 0.3);
}

.vowd__filtre__text {
    display: inline-block;
    font-size: 6vw;
    line-height: 7vw;
    width: 60vw;
    height: auto;
    margin-top: 25vw;
    color: rgb(0, 255, 221);
}

.vowd__logo {
    position: absolute;
    top: 13vw;
    left: 52%;
    transform: translate(-52%, 0);
    width: 14vw;
    height: auto;
    opacity: .2;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre__text {
        line-height: 6.5vw;
    }

    .vowd__logo {
        top: 12vw;
        width: 15vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 4.5vw;
        line-height: 5.5vw;
    }  
    
    .vowd__filtre__text {
        margin-top: 35vw;
    }
}