/**********************************************************************************************/
/****************************************** scrollToTop ***************************************/
/**********************************************************************************************/

.top-to-btm {
    position: relative;
    z-index: 2000;
}

.icon-position {
    position: fixed;
    top: 90vw;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: rgba(52, 190, 167, .6);
    border-radius: 6px;
    height: 50px;
    width: 50px;
    color: white;
    cursor: pointer;
    transition: all .7s ease-in-out;
}

.icon-style:hover {
    animation: none;
    background: rgb(52, 190, 167);
    color: white;
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 450px ****************************************/

@media screen and (min-width: 451px) {

    /**********************************************************************************************/
    /****************************************** scrollToTop ***************************************/
    /**********************************************************************************************/

    .icon-position {
      top: 150px;
      z-index: 3000;
    }
}