/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/
/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/

    .famille {
        width: 105.6vw;
    }

    .link__button {
        padding-left: .5vw;
        width: 20.2vw;
        height: 5vw;
    }

    .background__button {
        top: -18.5vw;
    }
    
    .link__button__btn {
        margin-right: 1.2vw;
        font-size: 4vw;
    }
    
    .link__button__text {
        font-size: 1.8vw;
        letter-spacing: .04vw;
    }

    .famille {
        width: 142.222vw;
    }

    .background__plus {
        font-size: 3vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /****************************************** background ****************************************/
    /**********************************************************************************************/
    
    .famille {
        width: 103vw;
    }

    .link__button {
        padding-left: .5vw;
        width: 15.5vw;
        height: 4vw;
    }

    .background__button {
        top: -23vw;
    }
    
    .link__button__btn {
        margin-right: .8vw;
        font-size: 3vw;
    }
    
    .link__button__text {
        font-size: 1.3vw;
        letter-spacing: .06vw;
        line-height: 1.6vw;
    }

    .background__plus {
        margin-right: 8vw;
        top: 40vw;
        padding: .6vw 1vw .6vw 1vw;
        font-size: 2vw;
        letter-spacing: .073vw;
    }
}