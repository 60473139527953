/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 767px ****************************************/

@media screen and (min-width: 768px) {

    /**********************************************************************************************/
    /********************************************* banner *****************************************/
    /**********************************************************************************************/

    .banner {
        padding-left: 5vw;
        height: 65vw;
        margin-bottom: -3vw;
    }

    .banner__picture1 {
        display: none;
    }

    .banner__logo-mutuact {
        top: -20vw;
        margin-top: -50vw;
        width: 60vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* banner *****************************************/
    /**********************************************************************************************/
    
    .banner__picture1 {
        display: none;
    }
    
    .banner__picture2 {
        display: block;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* banner *****************************************/
    /**********************************************************************************************/

    .banner {
        padding-left: 21.9vw;
    }

    .banner__picture1 {
        display: none;
    }
    
    .banner__picture2 {
        display: block;
    }

    .banner__logo-mutuact {
        top: -32vw;
        margin-top: -40vw;
        width: 40vw;
    }
}