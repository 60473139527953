/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 991px ****************************************/

@media screen and (min-width: 992px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 5vw;
        line-height: 5.5vw;
    }  
    
    .vowd__filtre__text {
        width: 50vw;
    }
}

/********************************************* media queries ************************************************/
/************************** New rules if the screen is at more 1199px ****************************************/

@media screen and (min-width: 1200px) {

    /**********************************************************************************************/
    /********************************************* vowd *******************************************/
    /**********************************************************************************************/

    .vowd__filtre {
        font-size: 4.5vw;
        line-height: 5vw;
    }  
    
    .vowd__filtre__text {
        width: 50vw;
    }
}